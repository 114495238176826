var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c("b-col", [
            _c("p", { staticClass: "h3" }, [_vm._v("受付情報入力")])
          ])
        ],
        1
      ),
      _c(
        "b-alert",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.state === _vm.ViewState.Fatal,
              expression: "state === ViewState.Fatal"
            }
          ],
          attrs: { show: "", variant: "danger" }
        },
        [
          _c("p", { staticClass: "mb-0" }, [
            _vm._v("・" + _vm._s(_vm.state.message))
          ])
        ]
      ),
      _c(
        "b-alert",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.state === _vm.ViewState.Error,
              expression: "state === ViewState.Error"
            }
          ],
          attrs: { show: "", variant: "danger" }
        },
        [_c("p", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.state.message))])]
      ),
      _c(
        "b-alert",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.state === _vm.ViewState.Invalid,
              expression: "state === ViewState.Invalid"
            }
          ],
          attrs: { show: "", variant: "warning" }
        },
        [
          _c("p", { staticClass: "mb-1" }, [
            _c("b", [_vm._v(_vm._s(_vm.state.message))])
          ]),
          _vm._l(_vm.state.errors, function(messages, index) {
            return _c("p", { key: index, staticClass: "mb-0" }, [
              _vm._v("・" + _vm._s(messages[0]))
            ])
          })
        ],
        2
      ),
      _c(
        "div",
        { attrs: { id: "modal-chat" } },
        [
          _c(
            "b-button",
            { attrs: { id: "show-modal" }, on: { click: _vm.openChat } },
            [_vm._v("音声入力")]
          ),
          _c("modal", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showModal,
                expression: "showModal"
              }
            ],
            ref: "chat",
            attrs: { form: _vm.form },
            on: { close: _vm.closeChat, addCompanion: _vm.addCompanion }
          })
        ],
        1
      ),
      _c(
        "b-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.create.apply(null, arguments)
            }
          }
        },
        [
          _c("p", { staticClass: "paragraph" }, [_vm._v("避難者基本情報")]),
          _c(
            "b-card",
            [
              _c(
                "b-form-group",
                { attrs: { label: "代表者氏名" } },
                [
                  _c("b-form-input", {
                    attrs: { type: "text", placeholder: "必須", required: "" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: "代表者氏名(かな)" } },
                [
                  _c("b-form-input", {
                    attrs: { type: "text", placeholder: "必須", required: "" },
                    model: {
                      value: _vm.form.name_kana,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name_kana", $$v)
                      },
                      expression: "form.name_kana"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: "郵便番号" } },
                [
                  _c("b-form-input", {
                    attrs: { type: "text", placeholder: "必須", required: "" },
                    on: { change: _vm.onChangePostalCode },
                    model: {
                      value: _vm.form.postal_code,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "postal_code", $$v)
                      },
                      expression: "form.postal_code"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                [
                  _c("div", { staticClass: "align-middle" }, [
                    _c(
                      "label",
                      [
                        _vm._v("住所 "),
                        _vm.state === _vm.ViewState.FindAddress
                          ? _c("b-spinner", {
                              staticClass: "mb-1",
                              attrs: { small: "", variant: "secondary" }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("b-form-input", {
                    attrs: { type: "text", placeholder: "必須", required: "" },
                    model: {
                      value: _vm.form.address,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: "電話番号" } },
                [
                  _c("b-form-input", {
                    attrs: { type: "tel", placeholder: "必須", required: "" },
                    model: {
                      value: _vm.form.phone_number,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phone_number", $$v)
                      },
                      expression: "form.phone_number"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: "生年月日" } },
                [
                  _c("b-form-input", {
                    attrs: { type: "date", placeholder: "必須", required: "" },
                    model: {
                      value: _vm.form.birthday,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "birthday", $$v)
                      },
                      expression: "form.birthday"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: "性別" } },
                [
                  _c("b-form-radio-group", {
                    attrs: { options: _vm.genderOptions(), required: "" },
                    model: {
                      value: _vm.form.gender,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "gender", $$v)
                      },
                      expression: "form.gender"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: "同行者" } },
                [
                  _c("b-form-radio-group", {
                    attrs: {
                      options: _vm.entryCompanionTypeOptions(),
                      required: ""
                    },
                    model: {
                      value: _vm.form.companion,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "companion", $$v)
                      },
                      expression: "form.companion"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: "車中避難" } },
                [
                  _c(
                    "b-form-checkbox-group",
                    {
                      model: {
                        value: _vm.form.stay_in_car,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "stay_in_car", $$v)
                        },
                        expression: "form.stay_in_car"
                      }
                    },
                    [_c("b-form-checkbox", [_vm._v("車中避難をする")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.form.companion === _vm.EntryCompanionType.Family.type
            ? _c(
                "div",
                [
                  _c("p", { staticClass: "paragraph mt-5" }, [
                    _c("b", [_vm._v("同居家族の情報")])
                  ]),
                  _vm._l(_vm.form.companions, function(companion, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "b-card",
                          { staticClass: "mt-3" },
                          [
                            _c("p", [
                              _c("small", { staticClass: "text-muted" }, [
                                _c("b", [
                                  _vm._v("同居家族 " + _vm._s(index + 1))
                                ])
                              ])
                            ]),
                            _c(
                              "b-form-group",
                              { attrs: { label: "氏名" } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: "必須",
                                    required: ""
                                  },
                                  model: {
                                    value: companion.name,
                                    callback: function($$v) {
                                      _vm.$set(companion, "name", $$v)
                                    },
                                    expression: "companion.name"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              { attrs: { label: "氏名(かな)" } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: "必須",
                                    required: ""
                                  },
                                  model: {
                                    value: companion.name_kana,
                                    callback: function($$v) {
                                      _vm.$set(companion, "name_kana", $$v)
                                    },
                                    expression: "companion.name_kana"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              { attrs: { label: "生年月日" } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    type: "date",
                                    placeholder: "必須",
                                    required: ""
                                  },
                                  model: {
                                    value: companion.birthday,
                                    callback: function($$v) {
                                      _vm.$set(companion, "birthday", $$v)
                                    },
                                    expression: "companion.birthday"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              { attrs: { label: "性別" } },
                              [
                                _c("b-form-radio-group", {
                                  attrs: {
                                    options: _vm.genderOptions(),
                                    required: ""
                                  },
                                  model: {
                                    value: companion.gender,
                                    callback: function($$v) {
                                      _vm.$set(companion, "gender", $$v)
                                    },
                                    expression: "companion.gender"
                                  }
                                })
                              ],
                              1
                            ),
                            index > 0
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeCompanion(index)
                                      }
                                    }
                                  },
                                  [_vm._v("削除")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-4",
                      attrs: { variant: "dark" },
                      on: { click: _vm.addCompanion }
                    },
                    [_vm._v("同居家族を追加")]
                  )
                ],
                2
              )
            : _vm._e(),
          _c("p", { staticClass: "paragraph mt-5" }, [_vm._v("健康について")]),
          _c(
            "b-card",
            [
              _c(
                "b-form-group",
                [
                  _c("label", [
                    _c("b", [
                      _vm._v("あなた・同居家族に下記枠内の症状はありますか？")
                    ])
                  ]),
                  _c(
                    "b-form-checkbox-group",
                    {
                      attrs: { stacked: "" },
                      model: {
                        value: _vm.form.enquetes.health.no1_1,
                        callback: function($$v) {
                          _vm.$set(_vm.form.enquetes.health, "no1_1", $$v)
                        },
                        expression: "form.enquetes.health.no1_1"
                      }
                    },
                    [
                      _c("b-form-checkbox", { attrs: { value: "1" } }, [
                        _vm._v("濃厚接触者で経過観察中")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "2" } }, [
                        _vm._v("PCR検査を受信し結果待ち")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "3" } }, [
                        _vm._v("過去14日以内に感染者との接触有り")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "4" } }, [
                        _vm._v("過去14日以内に風邪のような症状有り")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "5" } }, [
                        _vm._v("体温が37.5℃以上または平熱比1℃以上")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "6" } }, [
                        _vm._v("強いだるさがある")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "7" } }, [
                        _vm._v("息苦しさ、せき、たん、のどの痛み")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "8" } }, [
                        _vm._v("においや味を感じにくい")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.form.enquetes.health.no1_1.length > 0
                ? _c(
                    "b-form-group",
                    [
                      _c("label", [_c("b", [_vm._v("医療機関の受診")])]),
                      _c(
                        "b-form-radio-group",
                        {
                          attrs: { required: "" },
                          model: {
                            value: _vm.form.enquetes.health.no2_1,
                            callback: function($$v) {
                              _vm.$set(_vm.form.enquetes.health, "no2_1", $$v)
                            },
                            expression: "form.enquetes.health.no2_1"
                          }
                        },
                        [
                          _c("b-form-radio", { attrs: { value: "no" } }, [
                            _vm._v("なし")
                          ]),
                          _c("b-form-radio", { attrs: { value: "yes" } }, [
                            _vm._v("あり")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.enquetes.health.no1_1.length > 0 &&
              _vm.form.enquetes.health.no2_1 === "yes"
                ? _c(
                    "div",
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", [
                            _c("small", { staticClass: "text-muted" }, [
                              _c("b", [_vm._v("受診日")])
                            ])
                          ]),
                          _c("b-form-input", {
                            attrs: {
                              type: "date",
                              placeholder: "必須",
                              required: ""
                            },
                            model: {
                              value: _vm.form.enquetes.health.no2_2,
                              callback: function($$v) {
                                _vm.$set(_vm.form.enquetes.health, "no2_2", $$v)
                              },
                              expression: "form.enquetes.health.no2_2"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        [
                          _c("label", [
                            _c("small", { staticClass: "text-muted" }, [
                              _c("b", [_vm._v("医療機関名")])
                            ])
                          ]),
                          _c("b-form-input", {
                            attrs: {
                              type: "text",
                              placeholder: "必須",
                              required: ""
                            },
                            model: {
                              value: _vm.form.enquetes.health.no2_3,
                              callback: function($$v) {
                                _vm.$set(_vm.form.enquetes.health, "no2_3", $$v)
                              },
                              expression: "form.enquetes.health.no2_3"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        [
                          _c("label", [
                            _c("small", { staticClass: "text-muted" }, [
                              _c("b", [_vm._v("診断結果")])
                            ])
                          ]),
                          _c("b-form-input", {
                            attrs: {
                              type: "text",
                              placeholder: "必須",
                              required: ""
                            },
                            model: {
                              value: _vm.form.enquetes.health.no2_4,
                              callback: function($$v) {
                                _vm.$set(_vm.form.enquetes.health, "no2_4", $$v)
                              },
                              expression: "form.enquetes.health.no2_4"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        [
                          _c("label", [
                            _c("small", { staticClass: "text-muted" }, [
                              _c("b", [_vm._v("薬の処方")])
                            ])
                          ]),
                          _c(
                            "b-form-radio-group",
                            {
                              attrs: { required: "" },
                              model: {
                                value: _vm.form.enquetes.health.no2_5,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form.enquetes.health,
                                    "no2_5",
                                    $$v
                                  )
                                },
                                expression: "form.enquetes.health.no2_5"
                              }
                            },
                            [
                              _c("b-form-radio", { attrs: { value: "no" } }, [
                                _vm._v("なし")
                              ]),
                              _c("b-form-radio", { attrs: { value: "yes" } }, [
                                _vm._v("あり")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                [
                  _c("label", [_c("b", [_vm._v("持病")])]),
                  _c(
                    "b-form-checkbox-group",
                    {
                      attrs: { stacked: "" },
                      model: {
                        value: _vm.form.enquetes.health.no3_1.answer,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form.enquetes.health.no3_1,
                            "answer",
                            $$v
                          )
                        },
                        expression: "form.enquetes.health.no3_1.answer"
                      }
                    },
                    [
                      _c("b-form-checkbox", { attrs: { value: "1" } }, [
                        _vm._v("呼吸器疾患")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "2" } }, [
                        _vm._v("高血圧")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "3" } }, [
                        _vm._v("糖尿病")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "4" } }, [
                        _vm._v("その他基礎疾患")
                      ]),
                      _vm.form.enquetes.health.no3_1.answer.includes("4")
                        ? _c("b-form-input", {
                            staticClass: "mt-1",
                            attrs: {
                              type: "text",
                              placeholder:
                                "その他にチェックを入れた方は入力してください",
                              required: _vm.form.enquetes.health.no3_1.answer.includes(
                                "4"
                              )
                            },
                            model: {
                              value: _vm.form.enquetes.health.no3_1.other,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.enquetes.health.no3_1,
                                  "other",
                                  $$v
                                )
                              },
                              expression: "form.enquetes.health.no3_1.other"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.form.enquetes.health.no3_1.answer.includes("4")
                ? _c(
                    "b-form-group",
                    [
                      _c("label", [
                        _c("small", { staticClass: "text-muted" }, [
                          _c("b", [_vm._v("現在の具体的な症状")])
                        ])
                      ]),
                      _c("b-form-textarea", {
                        attrs: {
                          placeholder:
                            "その他にチェックを入れた方は入力してください",
                          rows: "3",
                          "max-rows": "3"
                        },
                        model: {
                          value: _vm.form.enquetes.health.no3_2,
                          callback: function($$v) {
                            _vm.$set(_vm.form.enquetes.health, "no3_2", $$v)
                          },
                          expression: "form.enquetes.health.no3_2"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("p", { staticClass: "paragraph mt-5" }, [_vm._v("自宅等の情報")]),
          _c(
            "b-card",
            [
              _c(
                "b-form-group",
                [
                  _c("label", [_c("b", [_vm._v("避難理由")])]),
                  _c(
                    "b-form-checkbox-group",
                    {
                      attrs: { stacked: "" },
                      model: {
                        value: _vm.form.enquetes.home.no1_1.answer,
                        callback: function($$v) {
                          _vm.$set(_vm.form.enquetes.home.no1_1, "answer", $$v)
                        },
                        expression: "form.enquetes.home.no1_1.answer"
                      }
                    },
                    [
                      _c("b-form-checkbox", { attrs: { value: "1" } }, [
                        _vm._v("浸水の可能性があった")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "2" } }, [
                        _vm._v("土砂災害の可能性があった")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "3" } }, [
                        _vm._v("独居で不安")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "4" } }, [
                        _vm._v("その他")
                      ]),
                      _vm.form.enquetes.home.no1_1.answer.includes("4")
                        ? _c("b-form-input", {
                            staticClass: "mt-1",
                            attrs: {
                              type: "text",
                              placeholder:
                                "その他にチェックを入れた方は入力してください"
                            },
                            model: {
                              value: _vm.form.enquetes.home.no1_1.other,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.enquetes.home.no1_1,
                                  "other",
                                  $$v
                                )
                              },
                              expression: "form.enquetes.home.no1_1.other"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                [
                  _c("label", [_c("b", [_vm._v("住宅情報")])]),
                  _c(
                    "b-form-radio-group",
                    {
                      attrs: { stacked: "" },
                      model: {
                        value: _vm.form.enquetes.home.no2_1.answer,
                        callback: function($$v) {
                          _vm.$set(_vm.form.enquetes.home.no2_1, "answer", $$v)
                        },
                        expression: "form.enquetes.home.no2_1.answer"
                      }
                    },
                    [
                      _c("b-form-radio", { attrs: { value: "1" } }, [
                        _vm._v("戸建(平家)")
                      ]),
                      _c("b-form-radio", { attrs: { value: "2" } }, [
                        _vm._v("戸建(2階以上)")
                      ]),
                      _c("b-form-radio", { attrs: { value: "3" } }, [
                        _vm._v("アパート・マンション")
                      ]),
                      _vm.form.enquetes.home.no2_1.answer === "3"
                        ? _c("b-form-input", {
                            staticClass: "mt-1",
                            attrs: { type: "text", placeholder: "階数" },
                            model: {
                              value: _vm.form.enquetes.home.no2_1.floor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.enquetes.home.no2_1,
                                  "floor",
                                  $$v
                                )
                              },
                              expression: "form.enquetes.home.no2_1.floor"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                [
                  _c("label", [_c("b", [_vm._v("被害状況")])]),
                  _c(
                    "b-form-checkbox-group",
                    {
                      attrs: { stacked: "" },
                      model: {
                        value: _vm.form.enquetes.home.no3_1.answer,
                        callback: function($$v) {
                          _vm.$set(_vm.form.enquetes.home.no3_1, "answer", $$v)
                        },
                        expression: "form.enquetes.home.no3_1.answer"
                      }
                    },
                    [
                      _c("b-form-checkbox", { attrs: { value: "1" } }, [
                        _vm._v("なし")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "2" } }, [
                        _vm._v("床下浸水")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "3" } }, [
                        _vm._v("床上浸水")
                      ]),
                      _c("b-form-checkbox", { attrs: { value: "4" } }, [
                        _vm._v("その他")
                      ]),
                      _vm.form.enquetes.home.no3_1.answer.includes("4")
                        ? _c("b-form-input", {
                            staticClass: "my-1",
                            attrs: {
                              type: "text",
                              placeholder:
                                "その他にチェックを入れた方は入力してください"
                            },
                            model: {
                              value: _vm.form.enquetes.home.no3_1.other,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form.enquetes.home.no3_1,
                                  "other",
                                  $$v
                                )
                              },
                              expression: "form.enquetes.home.no3_1.other"
                            }
                          })
                        : _vm._e(),
                      _c("b-form-checkbox", { attrs: { value: "5" } }, [
                        _vm._v("不明")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-checkbox-group",
            { staticClass: "mt-4 mb-4", attrs: { required: "" } },
            [
              _c(
                "b-checkbox",
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "terms" }, target: "_blank" } },
                    [_vm._v("利用規約")]
                  ),
                  _vm._v("・ "),
                  _c(
                    "router-link",
                    { attrs: { to: { name: "privacy" }, target: "_blank" } },
                    [_vm._v("プライバシーポリシー")]
                  ),
                  _vm._v("に同意する ")
                ],
                1
              )
            ],
            1
          ),
          _vm.state != _vm.ViewState.Creating
            ? _c("b-button", { attrs: { type: "submit", variant: "dark" } }, [
                _vm._v("登録")
              ])
            : _c(
                "b-button",
                { attrs: { variant: "dark", disabled: "" } },
                [
                  _c("span", [_vm._v("登録  ")]),
                  _c("b-spinner", {
                    staticClass: "align-middle mb-1",
                    attrs: { small: "" }
                  })
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }