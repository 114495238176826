<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <b-navbar toggleable="lg" type="dark" variant="dark">
            <b-navbar-brand>音声入力</b-navbar-brand>
          </b-navbar>

          <div class="modal-body">
            <div v-show="this.isDevelopment">
              <!-- マイクON/OFF -->
              <label for="MicSwitch">
                <input type="checkbox" v-model="isMic" id="MicSwitch" @change="MicChange" />
                マイク
              </label>
              <!-- 音声再生パラメータ -->
              <div>
                <div>
                  <select v-model="voice">
                    <option v-for="(item, index) in voices" :key="index">
                      {{ item.name }} ({{ item.lang }})
                    </option>
                  </select>
                </div>
                <div>
                  <span>pitch</span> <input type="range" v-model="pitch" min="0" max="2" />{{ pitch }}
                </div>
                <div>
                  <span>rate</span> <input type="range" v-model="rate" min="0.5" max="2" step="0.1" />{{ rate }}
                </div>
              </div>
              <hr>
            </div>

            <!-- 会話内容 -->
            <div class="chat-window" ref="chatWindow">
              <div v-for="(message, index) in chatMessages" :key="index" v-bind:class="'bubble-wrap-' + message.role">
                <div v-if="message.content" v-bind:class="'bubble-' + message.role">
                  <div v-if="message.role == 'loading' || message.role == 'assistant'" class="assistant-icon">
                    <span class="material-symbols-outlined">support_agent</span>
                  </div>
                  <div class="chat-content">
                    {{ message.content }}
                  </div>
                </div>
                <div v-if="message.role == 'loading'" v-bind:class="'bubble-' + message.role"  >
                  <div v-if="message.role == 'loading' || message.role == 'assistant'" class="assistant-icon">
                    <span class="material-symbols-outlined">support_agent</span>
                  </div>
                  <div class="loading-animation chat-content">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <b-button id="show-modal" @click="close">終了</b-button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Configuration, OpenAIApi } from "openai"

export default {
  name: 'Chat',
  data() {
    return {
      // OpenAI
      systems: [],     // OpenAI用初期プロンプト
      messages: [],    // OpenAI用会話リスト
      functions: [],   // OpenAI用関数リスト

      // テキスト⇒音声
      speech: null,    // テキスト⇒音声 (Window.speechSynthesis)
      voice: 1,
      voices: null,
      pitch: "1",
      rate: "1",

      // 音声⇒テキスト
      mic: null,       // 音声⇒テキスト (Window.webkitSpeechRecognition)
      isMic: true,    // マイクON/OFF

      chatMessages:[],

      isDevelopment: false,
      isBusy: false,
      isMicStarted: false,

      // 避難者基本情報の関数リスト
      base_info_functions: [
        {
          name: "regist_name",
          description: "避難者基本情報として、代表者氏名を確認して、代表者氏名と代表者氏名のふりがなを返す",
          parameters: {
            type: "object",
            properties: {
              name: {
                type: "string",
                description: "代表者氏名 e.g. 山田 太郎",
              },
              name_kana: {
                type: "string",
                description: "代表者氏名のふりがな e.g. やまだ たろう",
              },
            },
            required: [ "name", "name_kana" ],
          }
        },
        {
          name: "regist_postal_code",
          description: "避難者基本情報として、郵便番号を確認する",
          parameters: {
            type: "object",
            properties: {
              postal_code: {
                type: "string",
                description: "郵便番号 e.g. 1000001",
              },
            },
            required: [ "postal_code" ],
          }
        },
        {
          name: "regist_address",
          description: "避難者基本情報として、住所を確認する",
          parameters: {
            type: "object",
            properties: {
              address: {
                type: "string",
                description: "住所 e.g. 東京都渋谷区道玄坂1-2-3",
              },
            },
            required: [ "address" ],
          }
        },
        {
          name: "regist_phone_number",
          description: "避難者基本情報として、電話番号を確認する",
          parameters: {
            type: "object",
            properties: {
              phone_number: {
                type: "string",
                description: "電話番号 e.g. 0312345678",
              },
            },
            required: [ "phone_number" ],
          }
        },
        {
          name: "regist_birthday",
          description: "避難者基本情報として、生年月日を確認する",
          parameters: {
            type: "object",
            properties: {
              birthday: {
                type: "string",
                description: "生年月日 e.g. 2023-12-01",
              },
            },
            required: [ "birthday" ],
          }
        },
        {
          name: "regist_gender",
          description: "避難者基本情報として、性別を確認する",
          parameters: {
            type: "object",
            properties: {
              gender: {
                type: "string",
                description: "性別 e.g. 男性、女性",
              },
            },
            required: [ "gender" ],
          }
        },
        {
          name: "regist_companion",
          description: "避難者基本情報として、同行者がいるかどうか、いる場合は、家族、その他のいずれかで確認する",
          parameters: {
            type: "object",
            properties: {
              companion: {
                type: "string",
                description: "同行者 e.g. なし、同居家族、その他",
              },
            },
            required: [ "companion" ],
          }
        },
        {
          name: "regist_stay_in_car",
          description: "避難者基本情報として、車中避難があるどうかを、はい、いいえのいずれかで確認する",
          parameters: {
            type: "object",
            properties: {
              stay_in_car: {
                type: "string",
                description: "車中避難があるかどうか e.g. はい、いいえ",
              },
            },
            required: [ "stay_in_car" ],
          }
        },
      ],
      // 同居家族の情報の関数リスト
      companion_info_functions: [
        {
          name: "regist_family_count",
          description: "同居家族の人数を確認する",
          parameters: {
            type: "object",
            properties: {
              family_count: {
                type: "integer",
                description: "同居家族の人数 e.g. 2",
              },
            },
            required: [ "family_count" ],
          }
        },
        {
          name: "regist_family_name",
          description: "同居家族の氏名を確認し、氏名と氏名のふりがな、何人目なのかを返す",
          parameters: {
            type: "object",
            properties: {
              family_name: {
                type: "string",
                description: "同居家族の氏名 e.g. 山田 花子",
              },
              family_name_kana: {
                type: "string",
                description: "同居家族の氏名のふりがな e.g. やまだ はなこ",
              },
              family_index: {
                type: "integer",
                description: "同居家族の何人目かのインデックス e.g. 0",
              },
            },
            required: [ "family_name", "family_name_kana", "family_index" ],
          }
        },
        {
          name: "regist_family_birthday",
          description: "同居家族の生年月日を確認し、何人目なのかを返す",
          parameters: {
            type: "object",
            properties: {
              family_birthday: {
                type: "string",
                description: "同居家族の生年月日 e.g. 1990-10-01",
              },
              family_index: {
                type: "integer",
                description: "同居家族の何人目かのインデックス e.g. 0",
              },
            },
            required: [ "family_birthday", "family_index" ],
          }
        },
        {
          name: "regist_family_gender",
          description: "同居家族の性別を確認し、何人目なのかを返す",
          parameters: {
            type: "object",
            properties: {
              family_gender: {
                type: "string",
                description: "同居家族の性別 e.g. 女性",
              },
              family_index: {
                type: "integer",
                description: "同居家族の何人目かのインデックス e.g. 0",
              },
            },
            required: [ "family_gender", "family_index" ],
          }
        },
      ],
      // 健康についての関数リスト
      symptoms_info_functions: [
        // 症状
        {
          name: "regist_health_no1_1_1",
          description: "健康について、濃厚接触者で経過観察中かどうかを確認する",
          parameters: {
            type: "object",
            properties: {
              health_no1_1_1: {
                type: "string",
                description: "濃厚接触者で経過観察中かどうか e.g. はい、いいえ",
              },
            },
            required: [ "health_no1_1_1" ],
          }
        },
        {
          name: "regist_health_no1_1_2",
          description: "健康について、PCR検査を受診し結果待ちかどうかを確認する",
          parameters: {
            type: "object",
            properties: {
              health_no1_1_2: {
                type: "string",
                description: "PCR検査を受診し結果待ちかどうか e.g. はい、いいえ",
              },
            },
            required: [ "health_no1_1_2" ],
          }
        },
        {
          name: "regist_health_no1_1_3",
          description: "健康について、過去14日以内に感染者との接触有りかどうかを確認する",
          parameters: {
            type: "object",
            properties: {
              health_no1_1_3: {
                type: "string",
                description: "過去14日以内に感染者との接触有りかどうか e.g. はい、いいえ",
              },
            },
            required: [ "health_no1_1_3" ],
          }
        },
        {
          name: "regist_health_no1_1_4",
          description: "健康について、過去14日以内に風邪のような症状有りかどうかを確認する",
          parameters: {
            type: "object",
            properties: {
              health_no1_1_4: {
                type: "string",
                description: "過去14日以内に風邪のような症状有りかどうか e.g. はい、いいえ",
              },
            },
            required: [ "health_no1_1_4" ],
          }
        },
        {
          name: "regist_health_no1_1_5",
          description: "健康について、体温が37.5℃以上または平熱比1℃以上かどうかを確認する",
          parameters: {
            type: "object",
            properties: {
              health_no1_1_5: {
                type: "string",
                description: "体温が37.5℃以上または平熱比1℃以上かどうか e.g. はい、いいえ",
              },
            },
            required: [ "health_no1_1_5" ],
          }
        },
        {
          name: "regist_health_no1_1_6",
          description: "健康について、強いだるさがあるかどうかを確認する",
          parameters: {
            type: "object",
            properties: {
              health_no1_1_6: {
                type: "string",
                description: "強いだるさがあるかどうか e.g. はい、いいえ",
              },
            },
            required: [ "health_no1_1_6" ],
          }
        },
        {
          name: "regist_health_no1_1_7",
          description: "健康について、息苦しさ、せき、たん、のどの痛みがあるかどうかを確認する",
          parameters: {
            type: "object",
            properties: {
              health_no1_1_7: {
                type: "string",
                description: "息苦しさ、せき、たん、のどの痛みがあるかどうか e.g. はい、いいえ",
              },
            },
            required: [ "health_no1_1_7" ],
          }
        },
        {
          name: "regist_health_no1_1_8",
          description: "健康について、においや味を感じにくいかどうかを確認する",
          parameters: {
            type: "object",
            properties: {
              health_no1_1_8: {
                type: "string",
                description: "においや味を感じにくいかどうか e.g. はい、いいえ",
              },
            },
            required: [ "health_no1_1_8" ],
          }
        },
      ],
      // 医療機関の受診
      hospital_info_functions: [
        {
          name: "regist_health_no2_1",
          description: "医療機関を受診したかどうか確認する",
          parameters: {
            type: "object",
            properties: {
              health_no2_1: {
                type: "string",
                description: "医療機関を受診したかどうか e.g. はい、いいえ",
              },
            },
            required: [ "health_no2_1" ],
          }
        },
        {
          name: "regist_health_no2_2",
          description: "医療機関の受診日を確認する",
          parameters: {
            type: "object",
            properties: {
              health_no2_2: {
                type: "string",
                description: "医療機関の受診日 e.g. 2023-12-05",
              },
            },
            required: [ "health_no2_2" ],
          }
        },
        {
          name: "regist_health_no2_3",
          description: "医療機関の名称を確認する",
          parameters: {
            type: "object",
            properties: {
              health_no2_3: {
                type: "string",
                description: "医療機関の名称 e.g. 国立医療センター",
              },
            },
            required: [ "health_no2_3" ],
          }
        },
        {
          name: "regist_health_no2_4",
          description: "医療機関の診断結果を確認する",
          parameters: {
            type: "object",
            properties: {
              health_no2_4: {
                type: "string",
                description: "医療機関の診断結果 e.g. 経過観察",
              },
            },
            required: [ "health_no2_4" ],
          }
        },
        {
          name: "regist_health_no2_5",
          description: "医療機関の薬の処方があるかどうか確認する",
          parameters: {
            type: "object",
            properties: {
              health_no2_5: {
                type: "string",
                description: "医療機関の薬の処方があるかどうか e.g. はい、いいえ",
              },
            },
            required: [ "health_no2_5" ],
          }
        },
      ],
      // 持病
      disease_info_functions: [
        {
          name: "regist_health_no3_1_1",
          description: "持病として、呼吸器疾患の持病があるかどうか確認する",
          parameters: {
            type: "object",
            properties: {
              health_no3_1_1: {
                type: "string",
                description: "呼吸器疾患の持病があるかどうか e.g. はい、いいえ",
              },
            },
            required: [ "health_no3_1_1" ],
          }
        },
        {
          name: "regist_health_no3_1_2",
          description: "持病として、高血圧の持病があるかどうかを確認する",
          parameters: {
            type: "object",
            properties: {
              health_no3_1_2: {
                type: "string",
                description: "高血圧の持病があるかどうか e.g. はい、いいえ",
              },
            },
            required: [ "health_no3_1_2" ],
          }
        },
        {
          name: "regist_health_no3_1_3",
          description: "持病として、糖尿病の持病があるかどうかを確認する",
          parameters: {
            type: "object",
            properties: {
              health_no3_1_3: {
                type: "string",
                description: "糖尿病の持病があるかどうか e.g. はい、いいえ",
              },
            },
            required: [ "health_no3_1_3" ],
          }
        },
        {
          name: "regist_health_no3_1_4",
          description: "持病として、その他の基礎疾患があるかどうかを確認する",
          parameters: {
            type: "object",
            properties: {
              health_no3_1_4: {
                type: "string",
                description: "その他の基礎疾患があるかどうか e.g. はい、いいえ",
              },
            },
            required: [ "health_no3_1_4" ],
          }
        },
        {
          name: "regist_health_no3_1_other",
          description: "その他の基礎疾患がある場合、基礎疾患の名称を確認する",
          parameters: {
            type: "object",
            properties: {
              health_no3_1_other: {
                type: "string",
                description: "その他の基礎疾患の名称 e.g. 痛風",
              },
            },
            required: [ "health_no3_1_other" ],
          }
        },
        {
          name: "regist_health_no3_2",
          description: "その他の基礎疾患がある場合、基礎疾患の現在の具体的な症状を確認する",
          parameters: {
            type: "object",
            properties: {
              health_no3_2: {
                type: "string",
                description: "基礎疾患の現在の具体的な症状 e.g. 足の指の腫れ",
              },
            },
            required: [ "health_no3_2" ],
          }
        },
      ],
      // 自宅等の情報の関数リスト
      evacuation_info_functions: [
        // 避難理由
        {
          name: "regist_home_no1_1_1",
          description: "避難理由として、浸水の可能性があったかどうかを確認する",
          parameters: {
            type: "object",
            properties: {
              home_no1_1_1: {
                type: "string",
                description: "浸水の可能性があったかどうか e.g. はい、いいえ",
              },
            },
            required: [ "home_no1_1_1" ],
          },
        },
        {
          name: "regist_home_no1_1_2",
          description: "避難理由として、土砂災害の可能性があったかどうかを確認する",
          parameters: {
            type: "object",
            properties: {
              home_no1_1_2: {
                type: "string",
                description: "土砂災害の可能性があったかどうか e.g. はい、いいえ",
              },
            },
            required: [ "home_no1_1_2" ],
          },
        },
        {
          name: "regist_home_no1_1_3",
          description: "避難理由として、独居で不安があったかどうかを確認する",
          parameters: {
            type: "object",
            properties: {
              home_no1_1_3: {
                type: "string",
                description: "独居で不安があったかどうか e.g. はい、いいえ",
              },
            },
            required: [ "home_no1_1_3" ],
          },
        },
        {
          name: "regist_home_no1_1_4",
          description: "避難理由として、その他の避難理由があったかどうかを確認する",
          parameters: {
            type: "object",
            properties: {
              home_no1_1_4: {
                type: "string",
                description: "その他の避難理由があったかどうか e.g. はい、いいえ",
              },
            },
            required: [ "home_no1_1_4" ],
          },
        },
        {
          name: "regist_home_no1_1_other",
          description: "避難理由として、その他の避難理由があった場合、その内容を確認する",
          parameters: {
            type: "object",
            properties: {
              home_no1_1_other: {
                type: "string",
                description: "その他の避難理由の内容 e.g. 倒壊の可能性があった。",
              },
            },
            required: [ "home_no1_1_other" ],
          },
        },
      ],
      // 住宅情報
      house_info_functions: [
        {
          name: "regist_home_no2_1",
          description: "住宅情報として、平屋の戸建、2階以上の戸建、アパート・マンションのいずれかを確認する",
          parameters: {
            type: "object",
            properties: {
              home_no2_1: {
                type: "string",
                description: "住宅情報 e.g. 平屋の戸建、2階以上の戸建、アパート・マンション",
              },
            },
            required: [ "home_no2_1" ],
          },
        },
        {
          name: "regist_home_no2_1_floor",
          description: "住宅情報がアパート・マンションの場合、階数を確認する",
          parameters: {
            type: "object",
            properties: {
              home_no2_1_floor: {
                type: "integer",
                description: "アパート・マンションの階数 e.g. 10",
              },
            },
            required: [ "home_no2_1_floor" ],
          },
        },
      ],
      // 被害状況
      damage_info_functions: [
        {
          name: "regist_home_no3_1_1",
          description: "被害状況があるかどうか確認する",
          parameters: {
            type: "object",
            properties: {
              home_no3_1_1: {
                type: "string",
                description: "被害状況があるかどうか e.g. はい、いいえ",
              },
            },
            required: [ "home_no3_1_1" ],
          },
        },
        {
          name: "regist_home_no3_1_2",
          description: "被害状況がある場合、床下浸水かどうか確認する",
          parameters: {
            type: "object",
            properties: {
              home_no3_1_2: {
                type: "string",
                description: "床下浸水があるかどうか e.g. はい、いいえ",
              },
            },
            required: [ "home_no3_1_2" ],
          },
        },
        {
          name: "regist_home_no3_1_3",
          description: "被害状況がある場合、床上浸水かどうか確認する",
          parameters: {
            type: "object",
            properties: {
              home_no3_1_3: {
                type: "string",
                description: "床上浸水があるかどうか e.g. はい、いいえ",
              },
            },
            required: [ "home_no3_1_3" ],
          },
        },
        {
          name: "regist_home_no3_1_4",
          description: "被害状況がある場合、その他の被害があるかどうか確認する",
          parameters: {
            type: "object",
            properties: {
              home_no3_1_4: {
                type: "string",
                description: "その他の被害があるかどうか e.g. はい、いいえ",
              },
            },
            required: [ "home_no3_1_4" ],
          },
        },
        {
          name: "regist_home_no3_1_other",
          description: "その他の被害がある場合、被害内容を確認する",
          parameters: {
            type: "object",
            properties: {
              home_no3_1_other: {
                type: "string",
                description: "その他の被害の被害内容 e.g. 建物の倒壊",
              },
            },
            required: [ "home_no3_1_other" ],
          },
        },
        {
          name: "regist_home_no3_1_5",
          description: "被害状況がある場合、その被害が不明かどうか確認する",
          parameters: {
            type: "object",
            properties: {
              home_no3_1_5: {
                type: "string",
                description: "被害状況が不明かどうか e.g. はい、いいえ",
              },
            },
            required: [ "home_no3_1_5" ],
          },
        },
      ]
    }
  },
  props: ['form'],
  mounted() {
    this.isDevelopment = process.env.NODE_ENV == 'development'
    // OpenAI用初期化データ
    this.systems = [
      { role: 'system', content: '避難所で避難者の登録手続きをお手伝いしてください。相手とはチャット画面で応対していると思って会話してください。はじめに代表者氏名を聞き出してください。'},
      { role: 'system', content: '一度の質問では、一つずつ項目を聞き出してください。'},
      { role: 'system', content: '応答は40文字以内で簡潔にしてください。'},
      { role: 'system', content: '分からない場合は分かりませんと回答してください。'},
      { role: 'system', content: '現在日は' + new Date().toLocaleString('ja-JP') + 'です。'},
      { role: 'system', content: '同じ内容のfunction_callを1度以上呼ばないでください。'},
    ]
    this.functions = this.base_info_functions
    this.messages.push({ role: 'user', content: 'よろしくお願いします。'}) // 最初にOpenAIに喋らせるために入れてます。
    // テキスト⇒音声 Window.speechSynthesis 初期化
    this.speech = window.speechSynthesis;
    this.speech.onvoiceschanged = () => {
      this.voices = this.speech.getVoices()   // getVoices()が非同期なので以下のイベントで取る必要があるようです。awaitでも行けたかも。
    }
    // 音声⇒テキスト Window.webkitSpeechRecognition 初期化
    this.MicStart()
  },
  methods: {
    // マイクからの音声認識開始
    MicStart() {
      const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition
      if (typeof SpeechRecognition === 'undefined') {
        this.speak('マイク機能対応のブラウザではないので音声入力は出来ません')
      }
      this.mic = new SpeechRecognition       // FireFoxだと動きません。
      this.mic.lang = 'ja-JP'
      this.mic.continuous = true
      this.mic.onresult = event => {
        // this.mic.stop()
        let autotext = '';
        for (let i = event.resultIndex; i < event.results.length; i++) {
          if (event.results[i].isFinal === true) {
            autotext += event.results[i][0].transcript
          }
        }
        if (autotext) {
          // 音声認識の結果テキストをOpenAIに送信
          console.log('mic:'+autotext)
          this.sendOpenAI(autotext)
        }
      }
      this.mic.onend = () => {
        this.mic.isMicStarted = false
        if (this.isMic === true && this.isBusy === false) {
          this.mic.start()
        }
        console.log('マイクOFF')
      }
      this.mic.onstart = () => {
        this.mic.isMicStarted = true
        console.log('マイクON')
      }
    },
    // マイクチェックボックス変更
    MicChange(param) {
      if (typeof param === 'boolean') {
        this.isMic = param
      }

      if (this.isMic) {
        this.messages = []
        this.mic.start()
        this.sendOpenAI(null)
      } else {
        this.mic.abort()
      }
    },
    changeText(text){
      // 音読不可な漢字を音読用のひらがなに変換
      text = text.replace(/避難所/g, 'ひなんじょ');
      text = text.replace(/戸建/g, 'こだて');
      text = text.replace(/受診日/g, 'じゅしんび');
      return text
    },
    // テキスト再生
    play(text) {
      // テキスト読み替え
      console.log('play:'+text)
      const utterThis = new SpeechSynthesisUtterance(this.changeText(text))
      utterThis.lang = "ja-JP"
      utterThis.voice = this.voices[this.voice]
      utterThis.pitch = this.pitch
      utterThis.rate = this.rate

      const that = this
      utterThis.onboundary = (event) =>{
        if (event.charIndex > text.length - 8){
          if (that.isMic === true && that.mic.isMicStarted == false) {
            that.mic.start()
          }
          that.isBusy = false

        }
      }
      // utterThis.onend = () => {
      //   if (that.isMic === true && that.mic.isMicStarted == false) {
      //     that.mic.start()
      //   }
      //   that.isBusy = false
      // }

      this.isBusy = true
      this.mic.stop()
      this.speech.speak(utterThis)
    },

    // OpenAIに会話送信
    async sendOpenAI(text) {
      console.log('send:'+text)
      // APIキーなどが丸見えなので通常フロント側でこのような実装はご法度
      // きちんと実装するときはOpenAIの部分はサーバー側で実装するように
      const configration = new Configuration({
        organization: process.env.VUE_APP_ORGANIZATION,
        apiKey: process.env.VUE_APP_API_KEY,
        dangerouslyAllowBrowser: true   // ブラウザ上で強制実行するためのフラグ。
      })
      const openai = new OpenAIApi(configration)
      if (text !== null){
        this.messages.push({ role: 'user', content: text })
        this.chatMessages.push({ role: 'user', content: text })
        this.scrollEnd()
      }
      if(this.chatMessages.filter(item => item.role == 'loading').length == 0){
        this.chatMessages.push({ role: 'loading' })
        this.scrollEnd()
      }

      // 要求
      var prompt = this.systems.concat(this.messages)
      const completion = await openai.createChatCompletion({
        model: 'gpt-3.5-turbo-1106',
        temperature: 0.2,
        messages: prompt,
        functions: this.functions,
        function_call: 'auto',
      })
 
      // 応答
      completion.data.choices.forEach(choice => {
        if (choice.message.content !== null) {
          // テキスト応答
          this.messages.push(choice.message)
          let loadingList = []
          this.chatMessages.filter((item, index) => { 
              if(item.role == 'loading') 
              loadingList.push(index)
          })
          this.chatMessages.splice(loadingList.slice(-1)[0], 1, choice.message)
          this.chatMessages = this.chatMessages.filter(item => item.role != 'loading')
          this.scrollEnd()
          this.play(choice.message.content)    // テキストを音声で再生

        } else if (choice.finish_reason === 'function_call') {
          const function_call = choice.message.function_call
          console.log(function_call.name, function_call.arguments)

          // 関数呼び出し
          var isCall = true

          // 避難者基本情報
          if (function_call.name === 'regist_name') {
            const { name, name_kana } = JSON.parse(function_call.arguments)
            this.form.name = name
            this.form.name_kana = name_kana
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_postal_code') {
            const { postal_code } = JSON.parse(function_call.arguments)
            this.form.postal_code = postal_code
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_address') {
            const { address } = JSON.parse(function_call.arguments)
            this.form.address = address
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_phone_number') {
            const { phone_number } = JSON.parse(function_call.arguments)
            this.form.phone_number = phone_number
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_birthday') {
            const { birthday } = JSON.parse(function_call.arguments)
            this.form.birthday = birthday
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_gender') {
            const { gender } = JSON.parse(function_call.arguments)
            this.form.gender = this.toGenderType(gender)
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_companion') {
            const { companion } = JSON.parse(function_call.arguments)
            this.form.companion = this.toCompanionType(companion)
            this.messages.push(choice.message)
            this.messages.push({ role: 'system', content: '引き続き、避難者基本情報として、車中避難があるどうかを聞き出してください。' }) // なしと言われると会話が終わることがあるので
          } else if (function_call.name === 'regist_stay_in_car') {
            const { stay_in_car } = JSON.parse(function_call.arguments)
            this.form.stay_in_car = this.toYesNoType(stay_in_car)
            this.messages.push(choice.message)
            if (this.form.companion === 'Family') {
              // 同行者が同居家族の場合、同居家族の情報を聞き出す
              console.log('call family info')
              this.functions = this.companion_info_functions
              this.messages.push({ role: 'system', content: '引き続き、同居家族の人数を聞き出してください。' })
            } else {
              // 上記以外は、症状について聞き出す
              console.log('call symptoms info')
              this.functions = this.symptoms_info_functions
              this.messages.push({ role: 'system', content: '引き続き、健康について、濃厚接触者で経過観察中かどうか聞き出してください。' })
            }

          // 同居家族の情報
          } else if (function_call.name === 'regist_family_count') {
            const { family_count } = JSON.parse(function_call.arguments)
            this.family_count = family_count
            const addCount = family_count - this.form.companions.length
            for (let i = 0; i < addCount; i++) {
              this.addCompanion()
            }
            this.messages.push(choice.message)
            this.messages.push({ role: 'system', content: '引き続き、同居家族の情報を'+family_count+'人分確認してください。情報は一人ずつ、氏名、生年月日、性別を聞き出してください。' })
          } else if (function_call.name === 'regist_family_name') {
            const { family_name, family_name_kana, family_index } = JSON.parse(function_call.arguments)
            this.form.companions[family_index].name = family_name
            this.form.companions[family_index].name_kana = family_name_kana
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_family_birthday') {
            const { family_birthday, family_index } = JSON.parse(function_call.arguments)
            this.form.companions[family_index].birthday = family_birthday
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_family_gender') {
            const { family_gender, family_index } = JSON.parse(function_call.arguments)
            this.form.companions[family_index].gender = this.toGenderType(family_gender)
            this.messages.push(choice.message)
            if (this.family_count === family_index + 1) {
              // 同居家族の情報を人数分聞き出せたら、症状について聞き出す
              console.log('call symptoms info')
              this.functions = this.symptoms_info_functions
              this.messages.push({ role: 'system', content: '引き続き、健康について、濃厚接触者で経過観察中かどうか聞き出してください。' })
            }

          // 健康について
          // 症状
          } else if (function_call.name === 'regist_health_no1_1_1') {
            const { health_no1_1_1 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(health_no1_1_1)) {
              this.form.enquetes.health.no1_1.push('1')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_health_no1_1_2') {
            const { health_no1_1_2 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(health_no1_1_2)) {
              this.form.enquetes.health.no1_1.push('2')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_health_no1_1_3') {
            const { health_no1_1_3 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(health_no1_1_3)) {
              this.form.enquetes.health.no1_1.push('3')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_health_no1_1_4') {
            const { health_no1_1_4 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(health_no1_1_4)) {
              this.form.enquetes.health.no1_1.push('4')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_health_no1_1_5') {
            const { health_no1_1_5 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(health_no1_1_5)) {
              this.form.enquetes.health.no1_1.push('5')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_health_no1_1_6') {
            const { health_no1_1_6 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(health_no1_1_6)) {
              this.form.enquetes.health.no1_1.push('6')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_health_no1_1_7') {
            const { health_no1_1_7 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(health_no1_1_7)) {
              this.form.enquetes.health.no1_1.push('7')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_health_no1_1_8') {
            const { health_no1_1_8 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(health_no1_1_8)) {
              this.form.enquetes.health.no1_1.push('8')
            }
            this.messages.push(choice.message)
            if (this.form.enquetes.health.no1_1.length > 0) {
              console.log('call hospital info')
              this.functions = this.hospital_info_functions
              this.messages.push({ role: 'system', content: '引き続き、医療機関の受診があるかどうかを聞き出してください。' })
            } else {
              console.log('call disease info')
              this.functions = this.disease_info_functions
              this.messages.push({ role: 'system', content: '医療機関を受診したかどうか、受診日、診断結果、薬の処方については聞き出さないでください。引き続き、持病として、呼吸器疾患の持病があるかどうか聞き出してください。' })
            }

          // 医療機関の受診
          } else if (function_call.name === 'regist_health_no2_1') {
            const { health_no2_1 } = JSON.parse(function_call.arguments)
            this.form.enquetes.health.no2_1 = this.toYesNoType(health_no2_1) ? 'yes' : 'no'
            this.messages.push(choice.message)
            if (this.toYesNoType(health_no2_1)) {
              this.messages.push({ role: 'system', content: '引き続き、医療機関の受診日を聞き出してください。' })
            }
          } else if (function_call.name === 'regist_health_no2_2') {
            const { health_no2_2 } = JSON.parse(function_call.arguments)
            this.form.enquetes.health.no2_2 = health_no2_2
            if (this.form.enquetes.health.no2_1 !=='yes') {
              this.form.enquetes.health.no2_1 = 'yes'
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_health_no2_3') {
            const { health_no2_3 } = JSON.parse(function_call.arguments)
            this.form.enquetes.health.no2_3 = health_no2_3
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_health_no2_4') {
            const { health_no2_4 } = JSON.parse(function_call.arguments)
            this.form.enquetes.health.no2_4 = health_no2_4
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_health_no2_5') {
            const { health_no2_5 } = JSON.parse(function_call.arguments)
            this.form.enquetes.health.no2_5 = this.toYesNoType(health_no2_5) ? 'yes' : 'no'
            this.messages.push(choice.message)
            console.log('call disease info')
            this.functions = this.disease_info_functions
            this.messages.push({ role: 'system', content: '引き続き、持病として、呼吸器疾患の持病があるかどうか聞き出してください。' })

          // 持病
          } else if (function_call.name === 'regist_health_no3_1_1') {
            const { health_no3_1_1 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(health_no3_1_1)) {
              this.form.enquetes.health.no3_1.answer.push('1')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_health_no3_1_2') {
            const { health_no3_1_2 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(health_no3_1_2)) {
              this.form.enquetes.health.no3_1.answer.push('2')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_health_no3_1_3') {
            const { health_no3_1_3 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(health_no3_1_3)) {
              this.form.enquetes.health.no3_1.answer.push('3')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_health_no3_1_4') {
            const { health_no3_1_4 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(health_no3_1_4)) {
              this.form.enquetes.health.no3_1.answer.push('4')
            }
            this.messages.push(choice.message)
            if (this.toYesNoType(health_no3_1_4)) {
              this.messages.push({ role: 'system', content: '引き続き、その他の基礎疾患の名称を聞き出してください。' })
            } else {
              // 健康について聞き出したので、避難理由を聞き出す
              console.log('call evacuation info')
              this.functions = this.evacuation_info_functions
              this.messages.push({ role: 'system', content: '引き続き、避難理由として、浸水の可能性があったかどうか聞き出してください。' })
            }
          } else if (function_call.name === 'regist_health_no3_1_other') {
            const { health_no3_1_other } = JSON.parse(function_call.arguments)
            this.form.enquetes.health.no3_1.other = health_no3_1_other
            if (this.form.enquetes.health.no3_1.answer.includes('4') === false) {
              this.form.enquetes.health.no3_1.answer.push('4')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_health_no3_2') {
            const { health_no3_2 } = JSON.parse(function_call.arguments)
            this.form.enquetes.health.no3_2 = health_no3_2
            if (this.form.enquetes.health.no3_1.answer.includes('4') === false) {
              this.form.enquetes.health.no3_1.answer.push('4')
            }
            this.messages.push(choice.message)
            // 健康について聞き出したので、避難理由を聞き出す
            console.log('call evacuation info')
            this.functions = this.evacuation_info_functions
            this.messages.push({ role: 'system', content: '引き続き、避難理由として、浸水の可能性があったかどうか聞き出してください。' })

          // 自宅等の情報
          // 避難理由
          } else if (function_call.name === 'regist_home_no1_1_1') {
            const { home_no1_1_1 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(home_no1_1_1)) {
              this.form.enquetes.home.no1_1.answer.push('1')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_home_no1_1_2') {
            const { home_no1_1_2 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(home_no1_1_2)) {
              this.form.enquetes.home.no1_1.answer.push('2')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_home_no1_1_3') {
            const { home_no1_1_3 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(home_no1_1_3)) {
              this.form.enquetes.home.no1_1.answer.push('3')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_home_no1_1_4') {
            const { home_no1_1_4 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(home_no1_1_4)) {
              this.form.enquetes.home.no1_1.answer.push('4')
            }
            this.messages.push(choice.message)
            if (this.toYesNoType(home_no1_1_4)) {
              this.messages.push({ role: 'system', content: '引き続き、その他の避難理由の内容を聞き出してください。' })
            } else {
              console.log('call house info')
              this.functions = this.house_info_functions
              this.messages.push({ role: 'system', content: '引き続き、住宅情報について聞き出してください。' })
            }
          } else if (function_call.name === 'regist_home_no1_1_other') {
            const { home_no1_1_other } = JSON.parse(function_call.arguments)
            this.form.enquetes.home.no1_1.other = home_no1_1_other
            if (this.form.enquetes.home.no1_1.answer.includes('4') === false) {
              this.form.enquetes.home.no1_1.answer.push('4')
            }
            this.messages.push(choice.message)
            console.log('call house info')
            this.functions = this.house_info_functions
            this.messages.push({ role: 'system', content: '引き続き、住宅情報について聞き出してください。' })

          // 住宅情報
          } else if (function_call.name === 'regist_home_no2_1') {
            const { home_no2_1 } = JSON.parse(function_call.arguments)
            var type = ''
            if (home_no2_1.includes('平屋')) {
              type = '1'
            } else if (home_no2_1.includes('2階')) {
              type = '2'
            } else if (home_no2_1.includes('アパート') || home_no2_1.includes('マンション')) {
              type = '3'
            }
            this.form.enquetes.home.no2_1.answer = type
            this.messages.push(choice.message)
            if (type === '3') {
              this.messages.push({ role: 'system', content: '引き続き、アパート・マンションの階数を聞き出してください。' })
            } else {
            console.log('call damage info')
            this.functions = this.damage_info_functions
            this.messages.push({ role: 'system', content: 'アパート・マンションの階数は聞き出さないでください。引き続き、被害状況があるかどうか聞き出してください。' })
            }
          } else if (function_call.name === 'regist_home_no2_1_floor') {
            const { home_no2_1_floor } = JSON.parse(function_call.arguments)
            this.form.enquetes.home.no2_1.floor = home_no2_1_floor
            this.messages.push(choice.message)
            console.log('call damage info')
            this.functions = this.damage_info_functions
            this.messages.push({ role: 'system', content: '引き続き、被害状況があるかどうか聞き出してください。' })

          // 被害状況
          } else if (function_call.name === 'regist_home_no3_1_1') {
            const { home_no3_1_1 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(home_no3_1_1) === false) { // いいえの場合にチェックをつける
              this.form.enquetes.home.no3_1.answer.push('1')
              this.messages.push({ role: 'system', content: '床下浸水、床上浸水、その他、不明について聞き出さないでください。' })
              this.messages.push({ role: 'system', content: '「チャット画面を閉じた後に入力内容に問題がないか確認をし、登録ボタンを押下して登録完了です」と言って質問を終えてください。' })
            } else {
              this.messages.push({ role: 'system', content: '引き続き、床下浸水かどうか聞き出してください。' })
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_home_no3_1_2') {
            const { home_no3_1_2 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(home_no3_1_2)) {
              this.form.enquetes.home.no3_1.answer.push('2')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_home_no3_1_3') {
            const { home_no3_1_3 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(home_no3_1_3)) {
              this.form.enquetes.home.no3_1.answer.push('3')
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_home_no3_1_4') {
            const { home_no3_1_4 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(home_no3_1_4)) {
              this.form.enquetes.home.no3_1.answer.push('4')
            }
            this.messages.push(choice.message)
            if (this.toYesNoType(home_no3_1_4)) {
              this.messages.push({ role: 'system', content: 'その他の被害の内容を聞き出してください。' })
            } else if (this.form.enquetes.home.no3_1.answer.length == 0) {
              this.messages.push({ role: 'system', content: '被害はあったが不明であるのかどうか聞き出してください。' })
            }
            this.messages.push(choice.message)
          } else if (function_call.name === 'regist_home_no3_1_other') {
            const { home_no3_1_other } = JSON.parse(function_call.arguments)
            this.form.enquetes.home.no3_1.other = home_no3_1_other
            if (this.form.enquetes.home.no3_1.answer.includes('4') === false) {
              this.form.enquetes.home.no3_1.answer.push('4')
            }
            this.messages.push(choice.message)
            this.messages.push({ role: 'system', content: '「チャット画面を閉じた後に入力内容に問題がないか確認をし、登録ボタンを押下して登録完了です」と言って質問を終えてください。' })
          } else if (function_call.name === 'regist_home_no3_1_5') {
            const { home_no3_1_5 } = JSON.parse(function_call.arguments)
            if (this.toYesNoType(home_no3_1_5)) {
              this.form.enquetes.home.no3_1.answer.push('5')
            }
            this.messages.push(choice.message)
            this.messages.push({ role: 'system', content: '「チャット画面を閉じた後に入力内容に問題がないか確認をし、登録ボタンを押下して登録完了です」と言って質問を終えてください。' })
          } else {
            // たまにfanction_callにないfanctionをぶっこんでくるときがある
            // 健康について、体温の測定をしていますか？など
            // その場合、
            // regist_health_no4_1 {
            // "health_no4_1": "はい"
            // }
            // のようなJSONを勝手に作り出して返してくる
            // ここで再帰的な呼び出しを止めると、会話が終わってしまうので、trueを設定しておく
            // isCall = false
            isCall = true
            this.messages.push(choice.message)
          }

          if (isCall) {
            // ファンクションコールの場合は再帰的に呼び出す必要があります。nパラメータを使えばいいのかも知れませんが。
            this.sendOpenAI(null)
          }
        }
      });
    },

    toGenderType(gender) {
      var type = ''
      if (gender === '男性') {
        type = 'Male'
      } else if (gender === '女性') {
        type = 'Female'
      }
      return type
    },
    toCompanionType(companion) {
      var type = ''
      if (companion === '同居家族') {
        type = 'Family'
      } else if (companion === 'その他') {
        type = 'Other'
      } else {
        type = 'None'
      }
      return type
    },
    toYesNoType(yesNo) {
      return yesNo === 'はい'
    },
    addCompanion() {
      this.$emit('addCompanion')
    },

    // modal独自関数
    scrollEnd(){
      window.setTimeout(() => {
        this.$refs.chatWindow.scrollTo(0, this.$refs.chatWindow.scrollHeight-this.$refs.chatWindow.clientHeight)
      }, 500)
    },
    close(){
      this.MicChange(false)
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 80vw;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}


.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.chat-window {
  height: 60vh;
  overflow: auto;
}

/* チャットウィンドウ*/
.bubble-wrap-assistant,
.bubble-wrap-loading,
.bubble-wrap-user {
  display: flex;
  margin-bottom: 10px;
}

.bubble-wrap-assistant,
.bubble-wrap-loading {
  justify-content: flex-start;
}

.bubble-wrap-user {
  justify-content: flex-end;
}

.bubble-loading,
.bubble-assistant,
.bubble-user {
  padding: 10px;
  border-radius: 10px;
}


.bubble-loading,
.bubble-assistant {
  background-color: lightyellow;
  display: flex;
  max-width: 70%;
}


.bubble-user {
  background-color: lightgreen;
  max-width: 60%;
}

.assistant-icon {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: solid #666666 1px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 5px 0 0;
}

.chat-content {
  flex: 1;
}

/* ローディング */
.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-animation>div {
  width: 5px;
  height: 5px;
  margin: 5px;
  background-color: #333;
  border-radius: 50%;
  animation: loading 1s ease-in-out infinite;
}

.loading-animation>div:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-animation>div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}
</style>