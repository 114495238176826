var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c(
          "div",
          { staticClass: "modal-container" },
          [
            _c(
              "b-navbar",
              { attrs: { toggleable: "lg", type: "dark", variant: "dark" } },
              [_c("b-navbar-brand", [_vm._v("音声入力")])],
              1
            ),
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.isDevelopment,
                      expression: "this.isDevelopment"
                    }
                  ]
                },
                [
                  _c("label", { attrs: { for: "MicSwitch" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isMic,
                          expression: "isMic"
                        }
                      ],
                      attrs: { type: "checkbox", id: "MicSwitch" },
                      domProps: {
                        checked: Array.isArray(_vm.isMic)
                          ? _vm._i(_vm.isMic, null) > -1
                          : _vm.isMic
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.isMic,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.isMic = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.isMic = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.isMic = $$c
                            }
                          },
                          _vm.MicChange
                        ]
                      }
                    }),
                    _vm._v(" マイク ")
                  ]),
                  _c("div", [
                    _c("div", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.voice,
                              expression: "voice"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.voice = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        _vm._l(_vm.voices, function(item, index) {
                          return _c("option", { key: index }, [
                            _vm._v(
                              " " +
                                _vm._s(item.name) +
                                " (" +
                                _vm._s(item.lang) +
                                ") "
                            )
                          ])
                        }),
                        0
                      )
                    ]),
                    _c("div", [
                      _c("span", [_vm._v("pitch")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.pitch,
                            expression: "pitch"
                          }
                        ],
                        attrs: { type: "range", min: "0", max: "2" },
                        domProps: { value: _vm.pitch },
                        on: {
                          __r: function($event) {
                            _vm.pitch = $event.target.value
                          }
                        }
                      }),
                      _vm._v(_vm._s(_vm.pitch) + " ")
                    ]),
                    _c("div", [
                      _c("span", [_vm._v("rate")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.rate,
                            expression: "rate"
                          }
                        ],
                        attrs: {
                          type: "range",
                          min: "0.5",
                          max: "2",
                          step: "0.1"
                        },
                        domProps: { value: _vm.rate },
                        on: {
                          __r: function($event) {
                            _vm.rate = $event.target.value
                          }
                        }
                      }),
                      _vm._v(_vm._s(_vm.rate) + " ")
                    ])
                  ]),
                  _c("hr")
                ]
              ),
              _c(
                "div",
                { ref: "chatWindow", staticClass: "chat-window" },
                _vm._l(_vm.chatMessages, function(message, index) {
                  return _c(
                    "div",
                    { key: index, class: "bubble-wrap-" + message.role },
                    [
                      message.content
                        ? _c("div", { class: "bubble-" + message.role }, [
                            message.role == "loading" ||
                            message.role == "assistant"
                              ? _c("div", { staticClass: "assistant-icon" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "material-symbols-outlined"
                                    },
                                    [_vm._v("support_agent")]
                                  )
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "chat-content" }, [
                              _vm._v(" " + _vm._s(message.content) + " ")
                            ])
                          ])
                        : _vm._e(),
                      message.role == "loading"
                        ? _c("div", { class: "bubble-" + message.role }, [
                            message.role == "loading" ||
                            message.role == "assistant"
                              ? _c("div", { staticClass: "assistant-icon" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "material-symbols-outlined"
                                    },
                                    [_vm._v("support_agent")]
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "loading-animation chat-content" },
                              [_c("div"), _c("div"), _c("div")]
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ]),
            _c(
              "div",
              { staticClass: "modal-footer" },
              [
                _vm._t("footer", function() {
                  return [
                    _c(
                      "b-button",
                      { attrs: { id: "show-modal" }, on: { click: _vm.close } },
                      [_vm._v("終了")]
                    )
                  ]
                })
              ],
              2
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }