/**
 * 性別
 */
const Gender = {
  Male: { type: 'Male', name: '男性', enabled: true },
  Female: { type: 'Female', name: '女性', enabled: true },
  Unknown: { type: 'Unknown', name: '不明', enabled: false },
}

export default {
  data() {
    return {
      Gender: Gender,
    }
  },
  methods: {
    toGender: function (type) {
      for (var key of Object.keys(Gender)) {
        const e = Gender[key]
        if (e.type === type) return e
      }
      return Gender.Unknown
    },
    genderOptions: function () {
      const options = []
      for (var key of Object.keys(Gender)) {
        const e = Gender[key]
        if (!e.enabled) continue
        options.push({ text: e.name, value: e.type })
      }
      return options
    },
  }
}
