<template>
  <div class="main">
    <b-row class="mt-4">
      <b-col><p class="h3">受付情報入力</p></b-col>
    </b-row>
    <b-alert v-show="state === ViewState.Fatal" show variant="danger"><p class="mb-0">・{{ state.message }}</p></b-alert>
    <b-alert v-show="state === ViewState.Error" show variant="danger"><p class="mb-0">{{ state.message }}</p></b-alert>
    <b-alert v-show="state === ViewState.Invalid" show variant="warning">
      <p class="mb-1"><b>{{ state.message }}</b></p>
      <p v-for="(messages, index) in state.errors" :key="index" class="mb-0">・{{ messages[0] }}</p>
    </b-alert>
    
    <!-- チャットウィンドウ -->
    <div id="modal-chat">
      <b-button id="show-modal" @click="openChat">音声入力</b-button>
      <!-- use the modal component, pass in the prop -->
      <modal v-show="showModal" @close="closeChat" @addCompanion="addCompanion" :form="form" ref="chat"/>
    </div>

    <b-form @submit.prevent="create">
      <!-- 避難者基本情報 -->
      <p class="paragraph">避難者基本情報</p>
      <b-card>
        <b-form-group label="代表者氏名">
          <b-form-input v-model="form.name" type="text" placeholder="必須" required />
        </b-form-group>
        <b-form-group label="代表者氏名(かな)">
          <b-form-input v-model="form.name_kana" type="text" placeholder="必須" required />
        </b-form-group>
        <b-form-group label="郵便番号">
          <b-form-input v-model="form.postal_code" type="text" @change="onChangePostalCode" placeholder="必須" required />
        </b-form-group>
        <b-form-group>
          <div class="align-middle">
            <label>住所 <b-spinner v-if="state === ViewState.FindAddress" small variant="secondary" class="mb-1"></b-spinner></label>
          </div>
          <b-form-input v-model="form.address" type="text" placeholder="必須" required />
        </b-form-group>
        <b-form-group label="電話番号">
          <b-form-input v-model="form.phone_number" type="tel" placeholder="必須" required />
        </b-form-group>
        <b-form-group label="生年月日">
          <b-form-input v-model="form.birthday" type="date" placeholder="必須" required />
        </b-form-group>
        <b-form-group label="性別">
          <b-form-radio-group v-model="form.gender" :options="genderOptions()" required />
        </b-form-group>
        <b-form-group label="同行者">
          <b-form-radio-group v-model="form.companion" :options="entryCompanionTypeOptions()" required />
        </b-form-group>
        <b-form-group label="車中避難">
          <b-form-checkbox-group v-model="form.stay_in_car">
            <b-form-checkbox>車中避難をする</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-card>

      <!-- 同居家族の情報 -->
      <div v-if="form.companion === EntryCompanionType.Family.type">
        <p class="paragraph mt-5"><b>同居家族の情報</b></p>
        <div v-for="(companion, index) in form.companions" :key="index">
          <b-card class="mt-3">
            <p><small class="text-muted"><b>同居家族 {{ index + 1 }}</b></small></p>
            <b-form-group label="氏名">
              <b-form-input v-model="companion.name" type="text" placeholder="必須" required />
            </b-form-group>
            <b-form-group label="氏名(かな)">
              <b-form-input v-model="companion.name_kana" type="text" placeholder="必須" required />
            </b-form-group>
            <b-form-group label="生年月日">
              <b-form-input v-model="companion.birthday" type="date" placeholder="必須" required />
            </b-form-group>
            <b-form-group label="性別">
              <b-form-radio-group v-model="companion.gender" :options="genderOptions()" required />
            </b-form-group>
            <b-button v-if="index > 0" variant="danger" @click="removeCompanion(index)">削除</b-button>
          </b-card>
        </div>
        <b-button class="mt-4" variant="dark" @click="addCompanion">同居家族を追加</b-button>
      </div>

      <!-- 健康について -->
      <p class="paragraph mt-5">健康について</p>
      <b-card>
        <b-form-group>
          <label><b>あなた・同居家族に下記枠内の症状はありますか？</b></label>
          <b-form-checkbox-group v-model="form.enquetes.health.no1_1" stacked>
            <b-form-checkbox value="1">濃厚接触者で経過観察中</b-form-checkbox>
            <b-form-checkbox value="2">PCR検査を受信し結果待ち</b-form-checkbox>
            <b-form-checkbox value="3">過去14日以内に感染者との接触有り</b-form-checkbox>
            <b-form-checkbox value="4">過去14日以内に風邪のような症状有り</b-form-checkbox>
            <b-form-checkbox value="5">体温が37.5℃以上または平熱比1℃以上</b-form-checkbox>
            <b-form-checkbox value="6">強いだるさがある</b-form-checkbox>
            <b-form-checkbox value="7">息苦しさ、せき、たん、のどの痛み</b-form-checkbox>
            <b-form-checkbox value="8">においや味を感じにくい</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
        <b-form-group v-if="form.enquetes.health.no1_1.length > 0">
          <label><b>医療機関の受診</b></label>
          <b-form-radio-group v-model="form.enquetes.health.no2_1" required>
            <b-form-radio value="no">なし</b-form-radio>
            <b-form-radio value="yes">あり</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <div v-if="form.enquetes.health.no1_1.length > 0 && form.enquetes.health.no2_1 === 'yes'">
          <b-form-group>
            <label><small class="text-muted"><b>受診日</b></small></label>
            <b-form-input v-model="form.enquetes.health.no2_2" type="date" placeholder="必須" required />
          </b-form-group>
          <b-form-group>
            <label><small class="text-muted"><b>医療機関名</b></small></label>
            <b-form-input v-model="form.enquetes.health.no2_3" type="text" placeholder="必須" required />
          </b-form-group>
          <b-form-group>
            <label><small class="text-muted"><b>診断結果</b></small></label>
            <b-form-input v-model="form.enquetes.health.no2_4" type="text" placeholder="必須" required />
          </b-form-group>
          <b-form-group>
            <label><small class="text-muted"><b>薬の処方</b></small></label>
            <b-form-radio-group v-model="form.enquetes.health.no2_5" required>
              <b-form-radio value="no">なし</b-form-radio>
              <b-form-radio value="yes">あり</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <b-form-group>
          <label><b>持病</b></label>
          <b-form-checkbox-group v-model="form.enquetes.health.no3_1.answer" stacked>
            <b-form-checkbox value="1">呼吸器疾患</b-form-checkbox>
            <b-form-checkbox value="2">高血圧</b-form-checkbox>
            <b-form-checkbox value="3">糖尿病</b-form-checkbox>
            <b-form-checkbox value="4">その他基礎疾患</b-form-checkbox>
            <b-form-input
              v-if="form.enquetes.health.no3_1.answer.includes('4')"
              v-model="form.enquetes.health.no3_1.other"
              type="text"
              placeholder="その他にチェックを入れた方は入力してください"
              :required="form.enquetes.health.no3_1.answer.includes('4')"
              class="mt-1" />
          </b-form-checkbox-group>
        </b-form-group>
        <b-form-group v-if="form.enquetes.health.no3_1.answer.includes('4')">
          <label><small class="text-muted"><b>現在の具体的な症状</b></small></label>
          <b-form-textarea v-model="form.enquetes.health.no3_2" placeholder="その他にチェックを入れた方は入力してください" rows="3" max-rows="3" />
        </b-form-group>
      </b-card>

      <!-- 自宅等の情報 -->
      <p class="paragraph mt-5">自宅等の情報</p>
      <b-card>
        <b-form-group>
          <label><b>避難理由</b></label>
          <b-form-checkbox-group v-model="form.enquetes.home.no1_1.answer" stacked>
            <b-form-checkbox value="1">浸水の可能性があった</b-form-checkbox>
            <b-form-checkbox value="2">土砂災害の可能性があった</b-form-checkbox>
            <b-form-checkbox value="3">独居で不安</b-form-checkbox>
            <b-form-checkbox value="4">その他</b-form-checkbox>
            <b-form-input
              v-if="form.enquetes.home.no1_1.answer.includes('4')"
              v-model="form.enquetes.home.no1_1.other"
              type="text"
              placeholder="その他にチェックを入れた方は入力してください"
              class="mt-1" />
          </b-form-checkbox-group>
        </b-form-group>
        <b-form-group>
          <label><b>住宅情報</b></label>
          <b-form-radio-group v-model="form.enquetes.home.no2_1.answer" stacked>
            <b-form-radio value="1">戸建(平家)</b-form-radio>
            <b-form-radio value="2">戸建(2階以上)</b-form-radio>
            <b-form-radio value="3">アパート・マンション</b-form-radio>
            <b-form-input
              v-if="form.enquetes.home.no2_1.answer === '3'"
              v-model="form.enquetes.home.no2_1.floor"
              type="text"
              placeholder="階数"
              class="mt-1" />
          </b-form-radio-group>
        </b-form-group>
        <b-form-group>
          <label><b>被害状況</b></label>
          <b-form-checkbox-group v-model="form.enquetes.home.no3_1.answer" stacked>
            <b-form-checkbox value="1">なし</b-form-checkbox>
            <b-form-checkbox value="2">床下浸水</b-form-checkbox>
            <b-form-checkbox value="3">床上浸水</b-form-checkbox>
            <b-form-checkbox value="4">その他</b-form-checkbox>
            <b-form-input
              v-if="form.enquetes.home.no3_1.answer.includes('4')"
              v-model="form.enquetes.home.no3_1.other"
              type="text"
              placeholder="その他にチェックを入れた方は入力してください"
              class="my-1" />
            <b-form-checkbox value="5">不明</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-card>

      <!-- 規約 -->
      <b-checkbox-group class="mt-4 mb-4" required>
        <b-checkbox>
          <router-link :to="{ name: 'terms' }" target="_blank">利用規約</router-link>・
          <router-link :to="{ name: 'privacy' }" target="_blank">プライバシーポリシー</router-link>に同意する
        </b-checkbox>
      </b-checkbox-group>
      <b-button v-if="state != ViewState.Creating" type="submit" variant="dark">登録</b-button>
      <b-button v-else variant="dark" disabled>
        <span>登録&nbsp;&nbsp;</span>
        <b-spinner small class="align-middle mb-1"></b-spinner>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import Gender from '@/commons/enums/Gender'
import EntryCompanionType from '@/commons/enums/EntryCompanionType'
import Chat from '@/views/Disasters/EntrySheets/Chat'

const ViewState = {
  Default: {},
  Loading: {},
  Loaded: {},
  FindAddress: {},
  Creating: {},
  Created: {},
  Invalid: { message: '入力エラーがあります', errors: [] },
  Error: { message: null },
  Fatal: { message: '予期しないエラーが発生しました' },
}

export default {
  mixins : [ Gender, EntryCompanionType ],
  data() {
    return {
      ViewState: ViewState,
      state: ViewState.Default,
      form: {
        name: "",
        name_kana: "",
        postal_code: "",
        address: "",
        phone_number: "",
        birthday: "",
        gender: "Male",
        companion: "None",
        stay_in_car: false,
        companions: [
          {
            name: "",
            name_kana: "",
            birthday: "",
            gender: "Male",
          },
        ],
        enquetes: {
          health: {
            no1_1: [],
            no2_1: "no",
            no2_2: "",
            no2_3: "",
            no2_4: "",
            no2_5: "no",
            no3_1: {
              answer: [],
              other: "",
            },
            no3_2: "",
          },
          home: {
            no1_1: {
              answer: [],
              other: "",
            },
            no2_1: {
              answer: 1,
              floor: "",
            },
            no3_1: {
              answer: [],
              other: "",
            },
          },
        },
      },
      showModal: false,
    };
  },
  components: {
    "modal":Chat,
  },
  mounted() {},
  methods: {
    async create() {
      this.state = ViewState.Creating
      await this.axios.post(`/api/disasters/entry_sheets/web`, {
        name: this.form.name,
        name_kana: this.form.name_kana,
        postal_code: this.form.postal_code,
        address: this.form.address,
        phone_number: this.form.phone_number,
        birthday: this.form.birthday,
        gender: this.form.gender,
        companion: this.form.companion,
        stay_in_car: this.form.stay_in_car,
        companions: this.form.companion === "Family" ? this.form.companions : [],
        enquetes: this.form.enquetes,
      }).then((response) => {
        this.state = ViewState.Created
        this.$router.push({ name: "disaster_entry_sheet_completed", params: { qrcode_data: response.data["qrcode_data"] } });
      }).catch((error) => {
        if (error.response.status === 400) {
          ViewState.Error.message = error.response.data.message
          this.state = ViewState.Error
        } else if (error.response.status === 404) {
          this.$router.push({ name: 'not_found'})
        } else if (error.response.status === 422) {
          ViewState.Invalid.errors = Object.values(error.response.data.errors)
          this.state = ViewState.Invalid
        } else {
          this.state = ViewState.Fatal
        }
        this.scrollToTop()
      })
    },
    onChangePostalCode() {
      if (this.form.postal_code.match(/^[0-9]{7}$/g) != null || /^\d{3}-?\d{4}$/g != null) {
        const newValue = this.form.postal_code.replace('-', '')
        this.form.postal_code = newValue
        this.findAddress(newValue)
      }
    },
    async findAddress(postal_code) {
      this.state = ViewState.FindAddress
      await this.axios.get(`/api/commons/address/${postal_code}`).then((response) => {
        this.state = ViewState.Loaded
        this.form.address = response.data.full_address
      }).catch(() => {
        this.state = ViewState.Loaded
      })
    },
    addCompanion() {
      const companion = {
        name: "",
        name_kana: "",
        birthday: "",
        gender: "Male",
      };

      this.form.companions.push(companion);
    },
    removeCompanion(index) {
      this.form.companions.splice(index, 1);
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    openChat(){
      this.showModal = true
      this.$refs.chat.MicChange(true)
    },
    closeChat() {
      // チャットウィンドウが閉じた時のイベント
      this.showModal = false
    },
  },
}
</script>

<style scoped>
.main {
  margin: 20px;
}
.paragraph {
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 10px;
}
.paragraph2 {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
