/**
 * 受付同行者タイプ
 */
const EntryCompanionType = {
  None: { type: 'None', name: '無し', enabled: true },
  Family: { type: 'Family', name: '同居家族', enabled: true },
  Other: { type: 'Other', name: 'その他', enabled: true },
  Unknown: { type: 'Unknown', name: '不明', enabled: false },
}

export default {
  data() {
    return {
      EntryCompanionType: EntryCompanionType,
    }
  },
  methods: {
    toEntryCompanionType: function (type) {
      for (var key of Object.keys(EntryCompanionType)) {
        const e = EntryCompanionType[key]
        if (e.type === type) return e
      }
      return EntryCompanionType.Unknown
    },
    entryCompanionTypeOptions: function () {
      const options = []
      for (var key of Object.keys(EntryCompanionType)) {
        const e = EntryCompanionType[key]
        if (!e.enabled) continue
        options.push({ text: e.name, value: e.type })
      }
      return options
    },
  }
}
